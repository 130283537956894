import React, { useMemo, useState, useEffect } from 'react';
import { FaCheck } from "react-icons/fa";
import { FaArrowRightLong } from "react-icons/fa6";
import Draggable from 'react-draggable';
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import DropImg from '../../img/DropImg.png';
import { BackendClient } from '../../api/api';


const priceOptions = [
  { value: '', label: 'Wybierz cenę' },
  { value: '0', label: '0' },
  { value: '100', label: '100' },
  { value: '200', label: '200' },
  { value: '300', label: '300' },
  { value: '400', label: '400' },
  { value: '500', label: '500' },
  { value: '750', label: '750' },
  { value: '1000', label: '1000' },
  { value: '1500', label: '1500' },
  { value: '2000', label: '2000' },
  { value: '2500', label: '2500' },
  { value: '3000', label: '3000' },
  { value: '3500', label: '3500' },
  { value: '4000', label: '4000' },
  { value: '5000', label: '5000' },
  { value: '6000', label: '6000' },
  { value: '7000', label: '7000' },
  { value: '8000', label: '8000' },
  { value: '9000', label: '9000' },
  { value: '10000', label: '10000' },
];
// qIndex = question index, number
// qData = question data, { "questionId": number, "question": "string", "questionType": "string", "answers": [{},{},{}] },
// nextQuestion = function to increase qIndex
// prevQuestion = function to decrease qIndex
// saveAnswer = function to save current answer in answers object
// currentAnswer = current answer, saved in answers object, string | number
// submitTest = function to submit test; sends API request with answers object in body


const TestChoice = ({ qIndex, qData, nextQuestion, prevQuestion, currentAnswer, saveAnswer, submitTest, lastQuestion = false }) => {
  const [finalAnswer, setFinalAnswer] = useState(currentAnswer || {});
  const [position, setPosition] = useState(currentAnswer || 0);
  const [textAreaAnswer, setTextAreaAnswer] = useState(currentAnswer || '');
  const [multiAnswer, setMultiAnswer] = useState(currentAnswer || []);
  const [priceVal, setPriceVal] = useState(currentAnswer || ['', '']);
  const [minPrice, setMinPrice] = useState(priceVal[0]);
  const [maxPrice, setMaxPrice] = useState(priceVal[1]);
  const backendClient = useMemo(() => new BackendClient(), []);

  // Update minPrice and ensure priceVal always has two values
  const handleMinPrice = (e) => {
    const newMinPrice = e.target.value;
    setMinPrice(newMinPrice);

    setPriceVal((prev) => {
      const newPriceVal = [...prev];
      newPriceVal[0] = newMinPrice; // Update the minPrice spot
      if (newPriceVal.length !== 2) {
        newPriceVal[1] = maxPrice; // Ensure maxPrice is set if priceVal.length was not 2
      }
      saveAnswer(qData.questionId, newPriceVal);
      return newPriceVal;
    });
  };

  // Update maxPrice and ensure priceVal always has two values
  const handleMaxPrice = (e) => {
    const newMaxPrice = e.target.value;
    setMaxPrice(newMaxPrice);

    setPriceVal((prev) => {
      const newPriceVal = [...prev];
      newPriceVal[1] = newMaxPrice; // Update the maxPrice spot
      if (newPriceVal.length !== 2) {
        newPriceVal[0] = minPrice; // Ensure minPrice is set if priceVal.length was not 2
      }
      saveAnswer(qData.questionId, newPriceVal);
      return newPriceVal;
    });
  };

  // const for styling ChooseOption question
  const firstFiveObjectsQ = qData.answers.slice(0, -1);
  const firstFiveStrings = firstFiveObjectsQ.map(item => item.answer)
  const lastObjectQ = qData.answers.slice(-1)[0];

  // saves answer for CHOICE_QUESTION
  const chooseOption = (opt) => {
    const newAnswer = opt === finalAnswer ? null : opt;
    setFinalAnswer(newAnswer);
    saveAnswer(qData.questionId, newAnswer);
  };

  const chooseOptions = (opt) => {
    let updatedAnswers;
    if ([...multiAnswer].includes(opt)) {
      updatedAnswers = multiAnswer.filter(item => item !== opt);
    } else {
      updatedAnswers = [...multiAnswer, opt];
    }

    setMultiAnswer(updatedAnswers);
    saveAnswer(qData.questionId, updatedAnswers);
  };

  const isMultipleChecked = () => {
    const sortedArr1 = firstFiveStrings.slice().sort();
    const sortedArr2 = multiAnswer.slice().sort();

    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) {
        return false;
      }
    }

    return true;
  };

  const chooseAllOptions = () => {
    if (isMultipleChecked()) {
      setMultiAnswer([]);
      saveAnswer(qData.questionId, []);
    } else {
      setMultiAnswer(firstFiveStrings);
      saveAnswer(qData.questionId, firstFiveStrings);
    }
  };

  // saves answer for SCALING_QUESTION
  const handleDragStop = (e, data) => {
    console.log(data);
    const finalPosition = Math.round(data.x / 130);
    setPosition(finalPosition);
    saveAnswer(qData.questionId, finalPosition);
  };

  // saves answer for OPENED_QUESTION
  const handleTextAreaChange = (event) => {
    const newValue = event.target.value;
    setTextAreaAnswer(newValue);
    saveAnswer(qData.questionId, newValue);
  };

  // handles position change of maroon block in SCALING_QUESTION
  const handleClickDrag = (num) => {
    if (num >= 0 && num <= 6) {
      setPosition(num);
      saveAnswer(qData.questionId, num);
    }
  }

  // method to handle a submit click for advanced survey
  const handleClickLastQ = () => {
    if (qIndex === 12) {
      submitTest();
    } else {
      nextQuestion();
    }
  };

  useEffect(() => {
    setFinalAnswer(currentAnswer);
    if (qData.questionType === 'SCALING_QUESTION') {
      setPosition(position || 0);
    }
  }, [currentAnswer, qData.questionType, position]);

  return (
    <div className='w-full lg:w-[800px] h-full'>

      {/* Question title */}
      <p className='text-md font-light '>
        {qIndex + 1}. {qData.question}
      </p>

      {/* CHOICE_QUESTION */}
      {qData.questionType === 'CHOICE_QUESTION' && (
        qData.answers.length === 6 ? (
          <div className='mt-2 md:mt-4'>
            <div className='w-full h-[150px] md:h-[200px] lg:h-[160px] flex flex-wrap md:flex-nowrap'>
              {firstFiveObjectsQ.map((item, index) => (
                <div className='h-[19vh] sm:h-full justify-between flex flex-col ml-2 w-[30%] md:w-auto' key={index}>
                  {item.imageUrl && (
                    <img
                      src={`https://images.weserv.nl/?url=${backendClient.getBaseUrl()}/images/${item.imageUrl}`}
                      alt={item.answer}
                      className=' h-3/4 mx-auto mb-2'
                    />
                  )}
                  <div className='flex items-start gap-1 mt-2 ml-6 h-1/4'>
                    <label htmlFor={`checkbox-${index}`} className='h-5 md:w-6 w-5 md:h-6 border-[3px] border-bronze rounded flex items-center justify-center'>
                      <input type="checkbox" className='hidden' id={`checkbox-${index}`} onClick={() => chooseOption(item.answer)} />
                      {finalAnswer === item.answer ? (
                        <FaCheck className='text-bronze h-4 md:w-5 w-4 md:h-5' />
                      ) : (
                        <FaCheck className='text-lightBeige h-4 md:w-5 w-4 md:h-5' />
                      )}
                    </label>
                    <p className={`text-xs md:text-sm ${finalAnswer === item.answer ? 'font-medium' : 'font-light'}`}>{item.answer}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className='w-full flex justify-center items-start border-t border-tan mt-3 pt-3'>
              <div className='flex items-start gap-1 h-1/4'>
                <label htmlFor={`checkbox-${5}`} className='md:w-6 md:h-6 h-5 w-5 border-[3px] border-bronze rounded flex items-center justify-center'>
                  <input type="checkbox" className='hidden' id={`checkbox-${5}`} onClick={() => chooseOption(lastObjectQ.answer)} />
                  {finalAnswer === lastObjectQ.answer ? (
                    <FaCheck className='text-bronze w-5 h-5' />
                  ) : (
                    <FaCheck className='text-lightBeige w-5 h-5' />
                  )}
                </label>
                <p className={`text-xs md:text-sm ${finalAnswer === lastObjectQ.answer ? 'font-medium' : 'font-light'}`}>{lastObjectQ.answer}</p>
              </div>
            </div>
          </div>
        ) : (
          <div className=' flex flex-wrap md:flex-nowrap w-full gap-2 mt-2 md:mt-4'>
            {qData.answers.map((item, index) => (
              <div className={`${item.imageUrl && 'h-[170px] md:h-[200px]'} justify-between flex flex-col ml-2 w-[29%] md:w-full md:mb-0 mb-2`} key={index}>
                {item.imageUrl && (
                  <img
                    src={`https://images.weserv.nl/?url=${backendClient.getBaseUrl()}/images/${item.imageUrl}`}
                    alt={item.answer}
                    className='h-3/5 md:h-3/4 mx-auto mb-2'
                  />
                )}
                <div className='flex items-center gap-1 md:mt-2 md:ml-6 h-1/4'>
                  <label htmlFor={`checkbox-${index}`} className='md:w-6 md:h-6 h-5 w-5 border-[3px] border-bronze rounded flex items-center justify-center'>
                    <input type="checkbox" className='hidden' id={`checkbox-${index}`} onClick={() => chooseOption(item.answer)} />
                    {finalAnswer === item.answer ? (
                      <FaCheck className='text-bronze h-4 md:w-5 w-4 md:h-5' />
                    ) : (
                      <FaCheck className='text-lightBeige h-4 md:w-5 w-4 md:h-5' />
                    )}
                  </label>
                  <p className={`text-xs md:text-sm ${finalAnswer === item.answer ? 'font-medium' : 'font-light'}`}>{item.answer}</p>
                </div>
              </div>
            ))}
          </div>
        )
      )}

      {/* MULTI_CHOICE_QUESTION */}
      {qData.questionType === 'MULTI_CHOICE_QUESTION' && (
        qData.answers.length === 6 && (
          <div className='mt-4'>
            <div className='w-full h-min md:h-[200px] lg:h-[160px] flex flex-wrap md:flex-nowrap'>
              {firstFiveObjectsQ.map((item, index) => (
                <div className=' sm:h-full justify-between flex flex-col ml-2 w-[30%] sm:w-auto' key={index}>
                  {item.imageUrl && (
                    <img
                      src={`https://images.weserv.nl/?url=${backendClient.getBaseUrl()}/images/${item.imageUrl}`}
                      alt={item.answer}
                      className=' h-3/4 mx-auto mb-2'
                    />
                  )}
                  <div className='flex items-start gap-1 mt-2 ml-6 h-1/4'>
                    <label htmlFor={`checkbox-${index}`} className='md:w-6 md:h-6 h-5 w-5 border-[3px] border-bronze rounded flex items-center justify-center'>
                      <input type="checkbox" className='hidden' id={`checkbox-${index}`} onClick={() => chooseOptions(item.answer)} />
                      {multiAnswer.includes(item.answer) ? (
                        <FaCheck className='text-bronze h-4 md:w-5 w-4 md:h-5' />
                      ) : (
                        <FaCheck className='text-lightBeige h-4 md:w-5 w-4 md:h-5' />
                      )}
                    </label>
                    <p className={`text-xs md:text-sm ${multiAnswer.includes(item.answer) ? 'font-medium' : 'font-light'}`}>{item.answer}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className='w-full flex justify-center items-start border-t border-tan mt-3 pt-3'>
              <div className='flex items-start gap-1 h-1/4'>
                <label htmlFor={`checkbox-${5}`} className='md:w-6 md:h-6 h-5 w-5 border-[3px] border-bronze rounded flex items-center justify-center'>
                  <input type="checkbox" className='hidden' id={`checkbox-${5}`} onClick={() => chooseAllOptions()} />
                  {isMultipleChecked() ? (
                    <FaCheck className='text-bronze h-4 md:w-5 w-4 md:h-5' />
                  ) : (
                    <FaCheck className='text-lightBeige h-4 md:w-5 w-4 md:h-5' />
                  )}
                </label>
                <p className={`text-xs md:text-sm ${isMultipleChecked() ? 'font-medium' : 'font-light'}`}>{lastObjectQ.answer}</p>
              </div>
            </div>
          </div>
        )
      )}


      {/* SCALING_QUESTION */}
      {qData.questionType === 'SCALING_QUESTION' && (
        <div className='flex flex-col w-full mt-6'>
          <div className='w-[800px] h-20px rounded-xl bg-[#e3d5b2] relative hidden lg:block'>
            <div className='flex'>

              <div
                className='h-[15px] w-[130px] absolute left-[-75] bg-[#ffffff00] cursor-pointer'
                onClick={() => handleClickDrag(0)}
              ></div>

              <div
                className='h-[15px] w-[130px] absolute left-[75px] bg-[#ffffff00] cursor-pointer'
                onClick={() => handleClickDrag(1)}
              ></div>

              <div
                className='h-[15px] w-[130px] absolute left-[205px] bg-[#ffffff00] cursor-pointer'
                onClick={() => handleClickDrag(2)}
              ></div>

              <div
                className='h-[15px] w-[130px] absolute left-[335px] bg-[#ffffff00] cursor-pointer'
                onClick={() => handleClickDrag(3)}
              ></div>

              <div
                className='h-[15px] w-[130px] absolute left-[460px] bg-[#ffffff00] cursor-pointer'
                onClick={() => handleClickDrag(4)}
              ></div>

              <div
                className='h-[15px] w-[130px] absolute left-[590px] bg-[#ffffff00] cursor-pointer'
                onClick={() => handleClickDrag(5)}
              ></div>

              <div
                className='h-[15px] w-[130px] absolute left-[720px] bg-[#ffffff00] cursor-pointer'
                onClick={() => handleClickDrag(6)}
              ></div>

            </div>

            <Draggable
              axis="x"
              handle=".handle"
              position={{ x: position * 130, y: 0 }}
              // position={null}
              grid={[130]}
              scale={1}
              bounds={'parent'}
              onStop={handleDragStop}
            >
              <div className="handle w-5 h-4 bg-maroon rounded-xl cursor-pointer"></div>
            </Draggable>
          </div>

          <div className='flex justify-between w-full mt-2'>
            <div className='flex flex-col items-center gap-2'>
              <p>0</p>
              <div
                onClick={() => handleClickDrag(position - 1)}
                style={{ userSelect: 'none' }}
                className='bg-gold rounded-full w-10 h-10 flex items-center justify-center border-2 border-bronze cursor-pointer'
              >
                <FaMinus color='#AA8639' />
              </div>
            </div>

            <div className='flex flex-col items-center gap-2'>
              <p>6</p>
              <div
                onClick={() => handleClickDrag(position + 1)}
                style={{ userSelect: 'none' }}
                className='bg-gold rounded-full w-10 h-10 flex items-center justify-center border-2 border-bronze cursor-pointer'
              >
                <FaPlus color='#AA8639' />
              </div>
            </div>
          </div>

          <div className='mt-4 text-maroon font-semibold text-3xl w-full lg:w-[800px] flex justify-center'>
            Twoja ocena: {position}
          </div>
        </div>
      )}


      {/* SCALING_CUSTOMIZABLE_QUESTION */}
      {qData.questionType === 'SCALING_CUSTOMIZABLE_QUESTION' && (
        <div className='flex w-full mt-6 gap-4 h-[200px]'>
          {/* Minimum Price Selection */}
          <div className="mb-4">
            <label className="font-light mb-1" htmlFor="minPrice">Cena od:</label>
            <div className="relative">
              <select
                id="minPrice"
                value={minPrice}
                onChange={handleMinPrice}
                className="flex flex-col mt-1 font-light border-2 border-bronze rounded py-2 pl-2 pr-8 appearance-none focus:outline-none focus:border-maroon w-full"
                style={{
                  backgroundImage: `url(${DropImg})`,
                  backgroundPosition: 'right 0.5rem center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '1rem',
                }}
              >
                {priceOptions
                  .filter(option => option.value === '' || !maxPrice || parseInt(option.value) <= parseInt(maxPrice))
                  .map((option) => (
                    <option
                      key={option.value}
                      value={option.value}>
                      {option.label}
                    </option>
                  ))}
              </select>
            </div>
          </div>

          {/* Maximum Price Selection */}
          <div className="mb-4">
            <label className="font-light mb-1" htmlFor="maxPrice">Cena do:</label>
            <div className="relative">
              <select
                id="maxPrice"
                value={maxPrice}
                onChange={handleMaxPrice}
                className="flex flex-col mt-1 font-light border-2 border-bronze rounded py-2 pl-2 pr-8 appearance-none focus:outline-none focus:border-maroon w-full"
                style={{
                  backgroundImage: `url(${DropImg})`,
                  backgroundPosition: 'right 0.5rem center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '1rem',
                }}
              >
                {priceOptions
                  .filter(option => option.value === '' || !minPrice || parseInt(option.value) >= parseInt(minPrice))
                  .map((option) => (
                    <option
                      key={option.value}
                      value={option.value}>
                      {option.label}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>
      )}


      {/* OPENED_QUESTION */}
      {qData.questionType === 'OPENED_QUESTION' && (
        <div>
          <textarea
            id="styledTextarea"
            name="styledTextarea"
            value={textAreaAnswer}
            onChange={handleTextAreaChange}
            placeholder="Twoja odpowiedź"
            rows="10"
            className="mt-4 block w-full px-3 py-2 border-2 border-bronze rounded-md focus:outline-none focus:ring-maroon focus:border-maroon sm:text-sm resize-none"
          >
          </textarea>
        </div>
      )}

      {/* Buttons */}
      <div className="absolute left-0 bottom-4 w-full flex gap-6 sm:relative sm:left-0 sm:mt-6 sm:bottom-0 items-center justify-center">
        {qIndex === 0 ? (
          <div className='text-maroon opacity-20 text-sm font-semibold'>
            Poprzednie pytanie
          </div>
        ) : (
          <div
            className='text-maroon text-sm font-semibold cursor-pointer'
            onClick={() => prevQuestion()}
          >
            Poprzednie pytanie
          </div>
        )}
        {lastQuestion && qIndex !== 12 ? (
          currentAnswer ? (
            <div
              className='h-8 text-sm bg-bronze text-white flex items-center px-4 rounded gap-3 cursor-pointer hover:bg-gold transition ease-in-out duration-300'
              onClick={submitTest}
            >
              <p>Zakończ ankietę</p>
            </div>
          ) : (
            <div
              className='h-8 text-sm text-bronze opacity-40 flex items-center px-4 rounded gap-3'
            >
              <p>Zakończ ankietę</p>
            </div>
          )
        ) : currentAnswer ? (
          <div
            className='h-8 text-sm bg-bronze text-white flex items-center px-4 rounded gap-3 cursor-pointer hover:bg-gold transition ease-in-out duration-300'
            onClick={handleClickLastQ}
          >
            <p>Dalej</p>
            <FaArrowRightLong />
          </div>
        ) : (
          <div className='h-8 text-sm text-bronze opacity-40 flex items-center px-4 rounded gap-3'>
            <p>Dalej</p>
            <FaArrowRightLong />
          </div>
        )}

      </div>

    </div>
  );
};

export default TestChoice;
