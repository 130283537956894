import React, { useCallback, useMemo, useState } from 'react'
import { RxCross2 } from "react-icons/rx";
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import { FaStar } from "react-icons/fa";
import { FaRegStar } from "react-icons/fa";
import { SlDislike } from "react-icons/sl";
import { SlLike } from "react-icons/sl";
import { BiMessageSquareAdd } from "react-icons/bi";
import { BackendClient } from '../../api/api';
import dialog from '../../img/dialog.png'


const Review = ({ closeReview, courseId, courseName }) => {
  const [rate1, setRate1] = useState(0);
  const [rate2, setRate2] = useState(0);
  const [rate3, setRate3] = useState(0);
  const [rate4, setRate4] = useState(0);
  const [like, setLike] = useState(true);
  const [comment, setComment] = useState('');
  const backendClient = useMemo(() => new BackendClient(), []);
  const [rated, setRated] = useState(false);
  const userId = localStorage.getItem('userId');

  const handleLike = () => {
    setLike(!like);
  };

  const handleComment = (e) => {
    setComment(e.target.value);
  }

  const reviewCourse = useCallback(async () => {
    if (rate1 === 0 || rate2 === 0 || rate3 === 0 || rate4 === 0) {
      console.log('courseId:', courseId);
      return;
    }
    try {
      await backendClient.postCourseOpinion({
        courseId: courseId,
        userId: userId,
        overallRating: like ? "POSITIVE" : "NEGATIVE",
        comprehensibilityOfContent: rate1,
        solvesTheEssenceOfTheProblem: rate2,
        valueForMoney: rate3,
        recommendToFriends: rate4,
        opinion: comment,
      });
      setRated(true);
    } catch (error) {
      console.log(error);
    }
  }, [backendClient, courseId, rate1, rate2, rate3, rate4, comment, like, userId]);

  return (
    <div className='fixed top-0 left-0 w-[100vw] h-[100vh] bg-[#f5e6cf55] backdrop-blur-sm flex items-center justify-center z-30'>
      <div className='bg-white p-4 rounded-lg w-[500px] h-max shadow-2xl'>

        <div className='w-full border-b border-lightBeige flex justify-end'>
          <RxCross2
            className='w-5 h-5 cursor-pointer mb-2'
            onClick={() => closeReview()}
          />
        </div>

        {rated ? (
          <div className='h-max w-full flex flex-col'>
            <div className='w-1/4 border-b-2 border-bronze mt-6'></div>
            <p className=' mt-6 text-4xl font-light'>Dziękujemy za <span className='text-bronze'>opinię</span>!</p>
            <div className='flex'>
              <div className='relative h-[300px] w-1/2 mt-4'>
                <img
                  src={`https://images.weserv.nl/?url=${backendClient.getBaseUrl()}/images/50&n=false`}
                  alt="gnomek"
                  className='absolute bottom-2 left-1 h-3/4 z-10'
                />
                <img
                  src={dialog}
                  alt="dialog"
                  className='absolute right-0 top-2 h-[100px]'
                  style={{ transform: 'rotateY(180deg)' }}
                />
                <div className='absolute top-4 right-5 text-xs flex flex-col items-center font-light'>
                  <p className='font-medium text-sm'>Cześć!</p>
                  <p>Codzienna nauka to</p>
                  <p>klucz do sukcesu!</p>
                </div>
              </div>
              <div className='w-1/2 flex items-center justify-center'>
                <h2 className=' mt-4 text-lg font-light'>Twoje zaangażowanie pozwala nam coraz lepiej dopasowywać dla <span className='text-bronze font-normal'>Ciebie</span> kursy</h2>

              </div>
            </div>
          </div>
        ) : (
          <>
            <div className='mt-6 w-full'>
              <div className='w-1/4 border-b-2 border-bronze'></div>
              <h2 className=' mt-4 text-3xl font-light'>Wystaw <span className='text-bronze'>opinię</span></h2>
            </div>

            <div className='mt-4'>
              <h3 className='text-lg'>Czy kurs "{courseName}" spełnił twoje oczekiwania?</h3>
              <p className='text-sm mt-1'>Twoja opinia pomoże nam w rozwoju</p>
              <div className='flex justify-center items-center w-full py-4 gap-4'>
                {like ? (
                  <SlLike
                    onClick={() => handleLike()}
                    className='p-3 w-10 h-10 border rounded text-white bg-bronze border-bronze cursor-pointer' />
                ) : (
                  <SlLike
                    onClick={() => handleLike()}
                    className='text-[#999] p-3 w-10 h-10 border border-[#bbb] rounded hover:text-white hover:bg-bronze hover:border-bronze animateSl' />
                )}
                {!like ? (
                  <SlDislike
                    onClick={() => handleLike()}
                    className='p-3 w-10 h-10 border rounded text-white bg-bronze border-bronze cursor-pointer' />
                ) : (
                  <SlDislike
                    onClick={() => handleLike()}
                    className='text-[#999] p-3 w-10 h-10 border border-[#bbb] rounded hover:text-white hover:bg-bronze hover:border-bronze animateSl' />
                )}
              </div>
            </div>

            <div>
              <Box
                sx={{
                  '& > legend': { mt: 2 },
                }}
              >
                <p className='font-light mb-1.5 text-sm'>Zrozumiałość treści kursu</p>
                <Rating
                  name="simple-controlled"
                  value={rate1}
                  icon={<FaStar className="w-5 h-5 mr-2 text-gold" />}
                  emptyIcon={<FaRegStar className="w-5 h-5 mr-2 text-gold" />}
                  max={5}
                  onChange={(event, newValue) => {
                    setRate1(newValue);
                  }}
                />
                <p className='font-light mt-4 mb-1.5 text-sm'>Czy kurs rozwiązuje istotę problemu?</p>
                <Rating
                  name="simple-controlled"
                  value={rate2}
                  icon={<FaStar className="w-5 h-5 mr-2 text-gold" />}
                  emptyIcon={<FaRegStar className="w-5 h-5 mr-2 text-gold" />}
                  max={5}
                  onChange={(event, newValue) => {
                    setRate2(newValue);
                  }}
                />
                <p className='font-light mt-4 mb-1.5 text-sm'>Stosunek ceny do jakości</p>
                <Rating
                  name="simple-controlled"
                  value={rate3}
                  icon={<FaStar className="w-5 h-5 mr-2 text-gold" />}
                  emptyIcon={<FaRegStar className="w-5 h-5 mr-2 text-gold" />}
                  max={5}
                  onChange={(event, newValue) => {
                    setRate3(newValue);
                  }}
                />
                <p className='font-light mt-4 mb-1.5 text-sm'>Czy poleciłbyś ten kurs znajomym?</p>
                <Rating
                  name="simple-controlled"
                  value={rate4}
                  icon={<FaStar className="w-5 h-5 mr-2 text-gold" />}
                  emptyIcon={<FaRegStar className="w-5 h-5 mr-2 text-gold" />}
                  max={5}
                  onChange={(event, newValue) => {
                    setRate4(newValue);
                  }}
                />
              </Box>
              <p className='font-light mt-4 mb-1.5 text-sm'>Czy chciałbyś/chciałabyś coś jeszcze dodać?</p>
              <textarea
                rows={4}
                value={comment}
                onChange={handleComment}
                placeholder='Twoja odpowiedź'
                className='border-2 border-gold rounded w-full font-light text-sm p-2 focus:outline-none focus:border-bronze'
              ></textarea>
            </div>

            <div className='flex justify-end mt-2'>
              <div
                onClick={() => reviewCourse()}
                className='px-6 py-3 w-max bg-maroon rounded-md text-white hover:bg-maroonHover animateSl flex gap-2 items-center'>
                <BiMessageSquareAdd className='w-5 h-5' />
                <p className='font-light text-sm'>Wystaw opinię</p>
              </div>
            </div>
          </>
        )}

      </div>
    </div>
  )
}

export default Review
