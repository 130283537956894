import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CourseBox from '../courses/CourseBox';
import {
  TbSquareRoundedChevronRight,
  TbSquareRoundedChevronLeft,
} from 'react-icons/tb';
import { BackendClient } from '../../api/api';
import Attendance from './Attendance';

const HomeCourses = ({ userId, profileId }) => {
  const [startIndex, setStartIndex] = useState(0);
  const [coursesFromApi, setCoursesFromApi] = useState([]);
  const [isSurveyCompleted, setIsSurveyCompleted] = useState(false);
  const [hasAssignedCourses, setHasAssignedCourses] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Added loading state
  const backendClient = useMemo(() => new BackendClient(), []);

  // Function to fetch courses
  const getCourses = useCallback(async () => {
    try {
      const response = await backendClient.getCoursesMatched(
        userId,
        'MATCHED'
      );
      setCoursesFromApi(response[0]?.courses || []);
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  }, [backendClient, userId]);

  // Function to fetch user data and update survey completion status
  const checkSurveyCompletion = useCallback(async () => {
    try {
      const userData = await backendClient.getUserData(userId);
      const isCompleted =
        userData?.isSurveysBasicAndExpandingCompleted || false;
      const hasAssignedCourses = userData?.haveAssignedCourses || false;
      setHasAssignedCourses(hasAssignedCourses);
      setIsSurveyCompleted(isCompleted);
      console.log('Surveys completed: ' + isCompleted);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }, [backendClient, userId]);

  // Fetch courses and survey completion status when userId or profileId changes
  useEffect(() => {
    const fetchData = async () => {
      if (userId && profileId) {
        await Promise.all([getCourses(), checkSurveyCompletion()]);
        setIsLoading(false); // Set loading to false after data is fetched
      }
    };
    fetchData();
  }, [getCourses, checkSurveyCompletion, userId, profileId]);

  const moveCourse = (direction) => {
    const totalCourses = coursesFromApi.length;

    if (totalCourses === 0) return;

    let newStartIndex = startIndex;
    if (direction === 'right') {
      newStartIndex = (startIndex + 1) % totalCourses;
    } else if (direction === 'left') {
      newStartIndex = (startIndex - 1 + totalCourses) % totalCourses;
    }

    setStartIndex(newStartIndex);
  };

  const coursesToShow =
    coursesFromApi.length > 0
      ? [
          coursesFromApi[startIndex],
          coursesFromApi[(startIndex + 1) % coursesFromApi.length],
        ]
      : [];

  if (isLoading) {
    // Optionally, you can return a loading indicator here
    return null;
  }

  return (
    <div>
      <h2>Frekwencja</h2>
      <div>
        <Attendance />
      </div>
      <h2 className="mt-4">Nowe kursy, które mogą Ci się spodobać</h2>
      <div className="w-full border border-[#AA863944] mt-2 p-2 rounded flex items-center mb-16 md:mb-0">
        {coursesToShow.length > 0 ? (
          <>
            <TbSquareRoundedChevronLeft
              className="h-7 w-7 text-maroon cursor-pointer w-[8%] md:w-auto"
              onClick={() => moveCourse('left')}
            />
            <div className="hidden md:flex flex-wrap w-full justify-center">
              {coursesToShow.map((course, index) => (
                <CourseBox key={index} course={course} isCompact={true} />
              ))}
            </div>
            <div className="flex md:hidden w-[84%] md:w-full justify-center">
              <CourseBox course={coursesToShow[0]} isCompact={true} />
            </div>
            <TbSquareRoundedChevronRight
              className="h-7 w-7 text-maroon cursor-pointer w-[8%] md:w-auto"
              onClick={() => moveCourse('right')}
            />
          </>
        ) : isSurveyCompleted && hasAssignedCourses ? (
          <div className="p-6 rounded-lg w-full flex flex-col items-start">
            <h2 className="font-bold text-2xl mb-4">
              Cieszymy się, że rozpocząłeś naukę!
            </h2>
            <p className="text-lg font-light mb-6">
              Jeżeli chcesz znaleźć więcej kursów skorzystaj z wyszukiwarki :)
            </p>
            <a
              href="/courses"
              className="bg-[#a62626] text-white py-3 px-6 rounded-md hover:bg-[#8c1e1e] transition-all"
            >
              Wyszukaj więcej kursów →
            </a>
          </div>
        ) : isSurveyCompleted ? (
          <div className="p-6 rounded-lg w-full flex flex-col items-start">
            <h2 className="font-bold text-2xl mb-4">
              Dziękujemy za wypełnienie ankiety!
            </h2>
            <p className="text-lg font-light mb-6">
              Właśnie pracujemy nad wybraniem dla Ciebie najlepszych kursów :)
            </p>
          </div>
        ) : (
          <div className="bg-[#faf0e0] p-6 rounded-lg w-full flex flex-col items-start">
            <h2 className="font-bold text-2xl mb-4">
              Nie masz dopasowanych kursów!
            </h2>
            <p className="text-lg font-light mb-6">
              Wypełnij ankietę, a ProRok dopasuje do Ciebie odpowiednie kursy :)
            </p>
            <a
              href="/start-test"
              className="bg-[#a62626] text-white py-3 px-6 rounded-md hover:bg-[#8c1e1e] transition-all"
            >
              Wypełnij ankietę →
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default HomeCourses;
