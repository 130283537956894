import React, { useMemo, useState } from 'react';
import { RxCross2 } from "react-icons/rx";
import { BackendClient } from '../../api/api';

const RecoverPassword = ({ closeRecovery }) => {
  const backendClient = useMemo(() => new BackendClient(), []);
  const [userEmail, setUserEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleEmailChange = (e) => {
    setUserEmail(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      await backendClient.patchRecoverPassword(userEmail);
      setSuccess(true);
      console.log('Message was sent');
    } catch (error) {
      console.log("ERROR HAPPENED: " + error);
      setErrorMessage('Wystąpił błąd podczas wysyłania wiadomości. Proszę spróbuj ponownie i zweryfikuj poprawność adresu email.');
    }
  };

  return (
    <div className='fixed top-0 left-0 w-[100vw] h-[100vh] bg-[#f5e6cf55] backdrop-blur-sm flex items-center justify-center z-30 text-black'>
      <div className='bg-white p-3 md:p-6 rounded-lg w-max h-max shadow-2xl mx-2'>
        <div className='w-full border-b border-lightBeige flex justify-end'>
          <RxCross2
            className='w-5 h-5 cursor-pointer mb-2'
            onClick={() => closeRecovery()}
          />
        </div>

        {success ? (
          <div className='w-[90vw] md:w-[500px] my-6'>
            <div className='border-b-2 border-bronze w-1/5'></div>
            <p className='text-2xl font-light mt-2'>
              Wiadomość została wysłana na{' '}
              <span className='text-bronze font-semibold'>podany</span> adres email.
            </p>
          </div>
        ) : (
          <div className='my-6 w-[90vw] md:w-[500px]'>
            <div className='border-b-2 border-bronze w-1/5'></div>
            <p className='text-2xl font-light mt-2'>
              Wpisz swój adres <span className='text-bronze font-semibold'>email</span>
              <br /> aby zresetować hasło.
            </p>
            <input
              value={userEmail}
              onChange={handleEmailChange}
              type="text"
              className='border-2 border-gold focus:outline-none focus:border-bronze rounded w-full p-1 text-lg mt-4'
            />
            {errorMessage && (
              <p className='text-red-500 mt-2'>{errorMessage}</p>
            )}
            <div
              onClick={() => handleSubmit()}
              className='py-1 px-4 bg-bronze rounded w-max text-white text-lg mt-4 hover:bg-gold animateSl'>
              Wyślij
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RecoverPassword;
