import React, { useCallback, useEffect, useState } from 'react';
import { FaArrowRightLong } from "react-icons/fa6";
import Dropdown from './Dropdown';
import DropdownForStars from './DropdownForStars';
import { FaStar } from "react-icons/fa";
import { CiFilter } from "react-icons/ci";

import { BackendClient, updateDemData } from '../../api/api';

const starCounts = [5, 4, 3, 2, 1];

const isScreenBiggerThan640px = () => {
  return window.matchMedia('(min-width: 640px)').matches;
};

const Filters = ({ handleQuery, deleteQuery, setForceUpdate }) => {

  const [categories, setCategories] = useState([]);
  const [checkedCategories, setCheckedCategories] = useState([]);

  const [trainingTypes, setTrainingTypes] = useState([]);
  const [checkedTrainingTypes, setCheckedTrainingTypes] = useState([]);


  const [courseForms, setCourseForms] = useState([]);
  const [checkedCourseForms, setCheckedCourseForms] = useState([]);

  const [contentTypes, setContentTypes] = useState([]);
  const [checkedContentTypes, setCheckedContentTypes] = useState([]);
  
  const [rating, setRating] = useState([]);
  const [checkedRating, setCheckedRating] = useState([]);
  
  const [isLoading, setIsLoading] = useState(false);
  const [minPrice, setMinPrice] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);
  const [comparePrices, setComparePrices] = useState(false);
  const [filterOptions, setFilterOptions] = useState(null);

  const isSmallScr = !isScreenBiggerThan640px();

  const [shownOptions, setShownOptions] = useState(isSmallScr);
  const backendClient = new BackendClient();

  const handleShowOptions = () => {
    setShownOptions(!shownOptions);
  }

  const resetFilters = () => {
    setIsLoading(true)
    setCategories([]);
    setTrainingTypes([]);
    setCourseForms([]);
    setContentTypes([]);
    setRating([]);
    setMinPrice(null);
    setMaxPrice(null);
    setIsLoading(false)
  }

  const handleFilterChange = (filterType, values) => {
    switch (filterType) {
      case 'categories':
        setCategories(values);
        break;
      case 'trainingTypes':
        setTrainingTypes(values);
        break;
      case 'courseForms':
        setCourseForms(values);
        break;
      case 'contentTypes':
        setContentTypes(values);
        break;
      case 'rating':
        setRating(values);
        break;
      default:
        break;
    }
  };

  const buildSearchCriteria = useCallback(() => {
    const criteria = [];

    criteria.push(categories.length > 0 ? `contentCategory:{${categories.join(',')}}` : null);
    criteria.push(trainingTypes.length > 0 ? `typeOfContentList:{${trainingTypes.join(',')}}` : null);
    criteria.push(courseForms.length > 0 ? `formOfKnowledge:{${courseForms.join(',')}}` : null );
    criteria.push(contentTypes.length > 0 ? `trainingMode:{${contentTypes.join(',')}}` : null);
    console.log("Rating: ", rating, "  Length: ", rating.length );
    if (rating.length > 0) criteria.push( rating.length > 0 ? `ratingOnGoogle:>=${Math.min(...rating)}` : null );  
    criteria.push(minPrice ? `minPrice:>=${minPrice}` : null);
    criteria.push(maxPrice ? `maxPrice:<=${maxPrice}` : null);

    return criteria.filter(item => item !== null).join(';');
  }, [categories, courseForms, contentTypes, minPrice, maxPrice, rating, trainingTypes]);

  const fetchCourses = useCallback(async () => {
    const searchCriteria = buildSearchCriteria();
    try {
      handleQuery(searchCriteria);
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  }, [buildSearchCriteria, handleQuery, ]);

  const checkPrices = useCallback(() => {
    const min = parseFloat(minPrice);
    const max = parseFloat(maxPrice);
  
    if (isNaN(min) || isNaN(max)) {
      setComparePrices(false);
      return;
    }
  
    if (max >= min) {
      setComparePrices(false);
    } else {
      setComparePrices(true);
    }
  }, [minPrice, maxPrice]);

  
  useEffect(() => {
    setIsLoading(true);
    const fetchCategories = async () => {
      try {
        const response = await backendClient.getAllCategories();
        console.log(response);
        setFilterOptions(response);
        console.log(filterOptions);
      } catch (error) {
        console.error("Błąd pobierania kategorii:", error);
      } finally {
        setIsLoading(false); // Ustawienie stanu ładowania na false po zakończeniu żądania
      }
    };
  
    fetchCategories();
  }, []);

  useEffect(() => {    
    fetchCourses();
  }, [categories, trainingTypes, courseForms, contentTypes, rating, minPrice, maxPrice, fetchCourses, isLoading]);

  useEffect(() => {
    checkPrices();
  }, [minPrice, maxPrice, checkPrices])

  return (
    <div className='flex flex-col justify-between border-2 border-maroon rounded-lg md:min-h-[95vh] mb-4 md:mb-0 w-full px-6 py-4'>
      <div>
        <div className='flex items-center justify-center gap-4'>
          <div
            className="px-6 py-2 h-12 bg-maroon text-lg text-white rounded-md cursor-pointer flex gap-4 items-center justify-center"
            onClick={async () => {
              try {
                setCheckedCategories([]);
                setCheckedContentTypes([]);
                setCheckedCourseForms([]);
                setCheckedTrainingTypes([]);
                setCheckedRating([])
                setRating([]);
                setMinPrice(null);
                setMaxPrice(null);
                resetFilters();
                buildSearchCriteria();
                deleteQuery();
                handleFilterChange();                                
                setForceUpdate(prev => prev + 1); 
              } catch {
                console.log("error")
              }
            }}
          >
            <p>Pełna oferta</p>
            <FaArrowRightLong />
          </div>
          {isSmallScr && (
            <div onClick={() => handleShowOptions()}>
              <CiFilter className='h-8 w-8 text-[#00000077]' />
            </div>
          )}
        </div>

        {!shownOptions && (
          <div className='mt-2'>
            {!filterOptions ? ( // Wyświetlenie loadera, gdy dane są pobierane
            <div>Ładowanie filtrów...</div> 
          ) : (
            <>
            <Dropdown
              title="Kategorie"              
              options={filterOptions.contentCategory}
              onChange={(values) => handleFilterChange('categories', values)}
              checkedItems={checkedCategories}
              setCheckedItems={setCheckedCategories} 
            />
            <Dropdown
              title="Typ szkoleń"              
              options={filterOptions.typeOfContent}
              onChange={(values) => handleFilterChange('trainingTypes', values)}
              checkedItems={checkedTrainingTypes}
              setCheckedItems={setCheckedTrainingTypes} 
            />
            <Dropdown
              title="Forma szkoleń"
              options={filterOptions.trainingMode}
              onChange={(values) => handleFilterChange('courseForms', values)}
              checkedItems={checkedCourseForms}
              setCheckedItems={setCheckedCourseForms} 
            />
            <Dropdown
              title="Rodzaj treści"
              options={filterOptions.formOfKnowledge}
              onChange={(values) => handleFilterChange('contentTypes', values)}
              checkedItems={checkedContentTypes}
              setCheckedItems={setCheckedContentTypes} 
            />
            <DropdownForStars
              title="Ocena kursu"
              checkedItems={checkedRating}
              setCheckedItems={setCheckedRating}             
              options={starCounts.map((count, index) => (
                {
                  name: (
                    <div key={count} className='flex gap-1'>
                      {Array.from({ length: count }).map((_, starIndex) => (
                        <FaStar key={starIndex} className='text-bronze' />
                      ))}
                    </div>
                  ), id: count
                }
              ))}
              onChange={(values) => handleFilterChange('rating', values)}
            />
            </>)}
            <div>
              <h3 className='text-xl'>Cena</h3>
              <div className='flex gap-4'>
                <input
                  type="number"
                  className='w-2/5 bg-transparent border-2 border-bronze h-8 rounded p-2 focus:border-maroon focus:outline-none text-sm'
                  placeholder='od PLN'
                  value={minPrice ?? ""}
                  onChange={(e) => setMinPrice(e.target.value)}
                />
                <input
                  type="number"
                  className='w-2/5 bg-transparent border-2 border-bronze h-8 rounded p-2 focus:border-maroon focus:outline-none text-sm'
                  placeholder='do PLN'
                  value={maxPrice ?? ""}
                  onChange={(e) => setMaxPrice(e.target.value)}
                />
              </div>
              {comparePrices && (
                <p className='text-red-500 font-normal mt-1 text-xs'>Cena maksymalna musi być nisza od minimalnej</p>
              )}
            </div>
          </div>
        )}
      </div>
      {!shownOptions && (
        <div
          className="px-6 mt-4 py-2 h-12 border-2 border-maroon text-maroon text-lg rounded-md cursor-pointer flex gap-4 items-center justify-center"
          onClick={() => {                       
            setCheckedCategories([]);
            setCheckedContentTypes([]);
            setCheckedCourseForms([]);
            setCheckedTrainingTypes([]);
            setCheckedRating([])
            setRating([]);
            setMinPrice("");
            setMaxPrice("");
            resetFilters();                 
            deleteQuery();     
            setForceUpdate(prev => prev + 1); 
          }}
        >
          Wyczyść filtry
        </div>
      )}
    </div>
  );
}

export default Filters;



