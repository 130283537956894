import React, { useState } from 'react'
import LoginBG from '../img/LoginBG.png';
import { FaArrowRightLong } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import { BsEyeSlash, BsEye } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import { BackendClient } from '../api/api'; // Update the import path according to your project structure
import RecoverPassword from './popUps/RecoverPassword';

const LogIn = () => {
  const navigate = useNavigate();
  const backendClient = new BackendClient(navigate);

  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [popUpRecovery, setPopUpRecovery] = useState(false);

  const handlePopUpRecovery = () => {
    setPopUpRecovery(!popUpRecovery);
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleEmailChange = (e) => {
    setUserEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setUserPassword(e.target.value);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validatePassword = (password) => {
    const re = /^[A-Za-z0-9!@#$%^&*()_+=?<>{}\[\]-]{10,}$/;
    return re.test(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(userEmail)) {
      setErrorMessage('Nieprawidłowy adres email');
      return;
    }
    if (!validatePassword(userPassword)) {
      setErrorMessage('Hasło musi mieć co najmniej 8 znaków, zawierać jedną wielką literę, jeden znak specjalny oraz jedną cyfrę.');
      return;
    }
    setErrorMessage('');

    try {
      await backendClient.login(userEmail, userPassword);
      console.log('Form submitted successfully');
      // Handle successful registration, e.g., redirect to login or show a success message
    } catch (error) {
      setErrorMessage('Login failed. Please try again.');
    }
  };

  return (
    <div className='flex w-full full-height-minus-5rem bg-[#0B0B0D] relative'>
      <div
        className='w-[220%] sm:w-[150%] lg:w-full full-height-minus-5rem bg-contain bg-no-repeat bg-gradient-to-br from-lime-500 via-neutral-700 to-stone-100 absolute left-[-120%] sm:left-[-70%] lg:left-0'
        style={{
          backgroundImage: `url(${LoginBG})`,
        }}
      ></div>
      <div className='w-[100%] lg:w-[42%] h-full flex flex-col justify-center items-center bg-gradient-to-l from-[#0B0B0D] from-40% lg:from-90% to-transparent to-100% lg:to-100% ml-auto z-10 sm:pl-[10vw] lg:pl-0'>
        <div className='w-full pl-4 pr-4 sm:pl-10 sm:pr-14 flex flex-col justify-between h-3/4 py-10 mt-auto'>
          <div>
            <div className='w-1/3 border-t-2 border-bronze'></div>
            <h2 className='text-white text-3xl py-4 font-light'>Logowanie ProRok</h2>
            <p className='text-white mb-6 font-light'>Uzupełnij swoje dane, aby się zalogować</p>
            <form onSubmit={handleSubmit}>
              <div className='mb-4'>
                <input
                  type="email"
                  placeholder="Twój email"
                  value={userEmail}
                  onChange={handleEmailChange}
                  className='w-full p-2 border-2 border-gold rounded focus:outline-none focus:border-bronze'
                />
              </div>
              <div className='mb-1 relative'>
                <input
                  type={showPassword ? "text" : "password"}
                  value={userPassword}
                  onChange={handlePasswordChange}
                  placeholder="Twoje hasło"
                  className='w-full p-2 border-2 border-gold rounded focus:outline-none focus:border-bronze'
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className='absolute inset-y-0 right-0 pr-3 flex items-center text-bronze'
                >
                  {showPassword ? <BsEyeSlash className='h-5 w-5' /> : <BsEye className='h-5 w-5' />}
                </button>
              </div>
              {errorMessage && <p className='text-red-500 my-2 w-full font-light'>{errorMessage}</p>}
              <p className='text-white text-sm flex gap-2 justify-start font-extralight mb-6'>
                Nie pamiętasz hasła?
                <div
                  className='text-bronze underline pointer hover:text-gold transition ease-in-out duration-300 cursor-pointer'
                  onClick={() => handlePopUpRecovery()}
                >
                  Resetuj hasło
                </div>
              </p>
              <button
                type="submit"
                className='h-8 mt-4 w-max mx-auto text-sm bg-bronze text-white flex items-center px-4 rounded gap-3 cursor-pointer hover:bg-gold transition ease-in-out duration-300'
              >
                <p>Zaloguj się</p>
                <FaArrowRightLong />
              </button>
            </form>
          </div>

          <p className='text-white mt-4 text-sm flex gap-2 justify-end font-extralight'>
            Nie masz jeszcze konta?
            <Link to="/register" className='text-bronze underline pointer hover:text-gold transition ease-in-out duration-300'>Rejestracja</Link>
          </p>
        </div>
      </div>
      {popUpRecovery && (
        <RecoverPassword closeRecovery={handlePopUpRecovery} />
      )}
    </div>
  )
}

export default LogIn;
