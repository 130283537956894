import './App.css';
import Header from './pages/Header';
import BasicTest from './pages/basicTest/BasicTest';
import HomeBT from './pages/basicTest/HomeBT';
import { Routes, Route } from 'react-router-dom';
import ResultBT from './pages/basicTest/ResultBT';
import { useEffect, useState } from 'react';
import { IoClose } from "react-icons/io5";
import ExpandTest from './pages/expandingTest/ExpandTest';
import LogIn from './pages/LogIn';
import LogInAndRedirect from './pages/LogInAndRedirect';
import SignUp from './pages/SignUp';
import Courses from './pages/courses/Courses';
import ResultET from './pages/expandingTest/ResultET';
import Panel from './pages/userPanel/Panel';
import SingleCoursePage from './pages/courses/SingleCoursePage';

const imgPopUp = 'https://en.meming.world/images/en/a/af/Understandable%2C_Have_a_Great_Day.jpg';

function App() {
  const [clickCount, setClickCount] = useState(0);
  const [startTime, setStartTime] = useState(null);
  const [showPsycho, setShowPsycho] = useState(false);

  useEffect(() => {
    const handleClick = () => {
      const now = Date.now();

      if (startTime === null || now - startTime > 1500) {
        setClickCount(1);
        setStartTime(now);
      } else {
        setClickCount(clickCount + 1);

        if (clickCount + 1 === 11) {
          showPsychoWindow();
          setClickCount(0);
          setStartTime(null);
        }
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [clickCount, startTime]);

  const showPsychoWindow = () => {
    setShowPsycho(true);
    // Your function logic here
  };
  return (
    <div className="font-inter box-border">
      <Header />

      <Routes>
        <Route path='/' element={<Panel />} />
        <Route path='/start-test' element={<HomeBT />} />
        <Route path='/basic-test' element={<BasicTest />} />
        <Route path='/basic-test-result/:result/:profileId/:isNewProfile' element={<ResultBT />} />
        <Route path='/expanding-test-result/:profileId/:isNewProfile' element={<ResultET />} />
        <Route path='/expanding-test/:profileId/:isNewProfile' element={<ExpandTest />} />
        <Route path='/login' element={<LogIn />} />
        <Route path='/login/:redirect' element={<LogInAndRedirect />} />
        <Route path='/register/:profileId?' element={<SignUp />} />
        <Route path='/courses' element={<Courses />} />
        <Route path='/course/:courseId' element={<SingleCoursePage />} />
        <Route path='/panel' element={<Panel />} />
      </Routes>


      {showPsycho && (
        <div className='absolute top-0 left-0 w-screen h-screen bg-[#00000099] z-10 flex items-center justify-center'>
          <div className='w-1/2 h-1/2 bg-lightBeige flex flex-col rounded'>
            <div className='h-1/5 flex justify-end'>
              <div
                className='bg-gold rounded-full w-7 h-7 flex items-center justify-center border-2 border-bronze cursor-pointer mr-2 mt-2'
                onClick={() => setShowPsycho(false)}
              >
                <IoClose className='h-6 w-6' color='#AA8639' />
              </div>
            </div>
            <div className='h-4/5 flex items-center justify-center'>
              <img src={imgPopUp} alt='imgPopUp' className='w-3/5 mb-14' />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
