import axios from "axios";

const HEADER_CONTENT_TYPE_JSON_AND_CHARSET_UTF8 = 'application/json;  charset=utf-8';

//prod api
const API_BASE_URL = 'https://api-prod.prorokszkoleniowy.pl';

//dev api
// const API_BASE_URL = 'https://api.prorokszkoleniowy.pl';

const getEncodedCredentials = () => {
  const username = 'ApostleOfAIAIgorithms';
  const password = '(0nv3rtingToThePathOfAI';
  return btoa(`${username}:${password}`);
};

export class BackendClient {
  constructor(navigate) {
    this.baseURL = API_BASE_URL;
    this.navigate = navigate; // Save navigate function in the class
  }

  getBaseUrl() {
    return API_BASE_URL;
  }

  getInstance() {
    const token = localStorage.getItem("refreshToken");

    if (token) {
      return axios.create({
        baseURL: this.baseURL,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
    }

    return axios.create({
      baseURL: this.baseURL
    });
  }

  getInstanceWithBasicAuth() {
    return axios.create({
      baseURL: this.baseURL,
      headers: {
        'Content-Type': HEADER_CONTENT_TYPE_JSON_AND_CHARSET_UTF8
      }
    });
  }

  getAccessToken(refreshToken) {
    return axios.get(`${API_BASE_URL}/access_token`, {
      headers: {
        'Authorization': `Bearer ${refreshToken}`
      }
    });
  }

  checkTokens() {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    const expiresAt = localStorage.getItem('accessTokenExpiresAt');
    const expiresAtR = localStorage.getItem('refreshTokenExpiresAt');
    const currentDate = new Date();

    // Check if refresh token has expired
    if (new Date(expiresAtR).getTime() < currentDate.getTime()) {
      console.log('Refresh token expired. Redirecting to login.');
      this.logout(); // Clear tokens and redirect to login
      return;
    }

    // Check if access token is missing or expired
    if (!accessToken || new Date(expiresAt).getTime() < currentDate.getTime()) {
      console.log('Access token expired or missing. Attempting to refresh.');

      return this.getAccessToken(refreshToken)
        .then(response => {
          const newAccessToken = response.data.token;
          const newExpiresAt = response.data.expiresAt;
          localStorage.setItem("accessToken", newAccessToken);
          localStorage.setItem("accessTokenExpiresAt", newExpiresAt);
        })
        .catch(error => {
          console.error('Failed to refresh access token. Redirecting to login.');
          this.logout(); // Clear tokens and redirect to login if refresh fails
        });
    }
  }


  getInstanceWithAccessToken() {
    const token = localStorage.getItem("accessToken");
    if (token) {
      return axios.create({
        baseURL: this.baseURL,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
    }

    return axios.create({
      baseURL: this.baseURL
    });
  }

  async login(email, password) {
    try {
      // Initial login to get refresh token
      const loginResponse = await this.getInstanceWithBasicAuth().post("/api-login", {
        email,
        pass: password
      },
      {
        headers: {
          'Content-Type': HEADER_CONTENT_TYPE_JSON_AND_CHARSET_UTF8,
        },
      });

      const { token: refreshToken, expiresAt: refreshTokenExpiresAt, userName, profileId, userId, isCompletedBasicAndExpandingSurveys } = loginResponse.data;

      // Save refresh token and its expiration time
      localStorage.setItem("refreshToken", refreshToken);

      localStorage.setItem("userName", userName);
      localStorage.setItem("profileId", profileId);
      localStorage.setItem("userId", userId);
      localStorage.setItem("isCompletedBasicAndExpandingSurveys", isCompletedBasicAndExpandingSurveys);
      localStorage.setItem("refreshTokenExpiresAt", refreshTokenExpiresAt);

      // Fetch access token using refresh token
      const accessTokenResponse = await this.getAccessToken(refreshToken);
      const { token: accessToken, expiresAt: accessTokenExpiresAt } = accessTokenResponse.data;

      // Save access token and its expiration time
      localStorage.setItem("accessToken", accessToken);

      localStorage.setItem("accessTokenExpiresAt", accessTokenExpiresAt);

      this.navigate('/panel', { state: { userName, profileId, userId } });
    } catch (error) {
      if (error.response) {
        console.error(`Login failed: ${error.response.status} - ${error.response.data}`);
      } else {
        console.error(`Login failed: ${error.message}`);
      }
      throw error;
    }
  }

  async loginWithCustomizedRedirection(email, password, redirect) {
    try {
      // Initial login to get refresh token
      const loginResponse = await this.getInstanceWithBasicAuth().post("/api-login", {
        email,
        pass: password
      },
      {
        headers: {
          'Content-Type': HEADER_CONTENT_TYPE_JSON_AND_CHARSET_UTF8,
        },
      });

      const { token: refreshToken, expiresAt: refreshTokenExpiresAt, userName, profileId, userId } = loginResponse.data;

      // Save refresh token and its expiration time
      localStorage.setItem("refreshToken", refreshToken);

      localStorage.setItem("userName", userName);
      localStorage.setItem("profileId", profileId);
      localStorage.setItem("userId", userId);

      localStorage.setItem("refreshTokenExpiresAt", refreshTokenExpiresAt);

      // Fetch access token using refresh token
      const accessTokenResponse = await this.getAccessToken(refreshToken);
      const { token: accessToken, expiresAt: accessTokenExpiresAt } = accessTokenResponse.data;

      // Save access token and its expiration time
      localStorage.setItem("accessToken", accessToken);

      localStorage.setItem("accessTokenExpiresAt", accessTokenExpiresAt);

      this.navigate('/' + redirect, { state: { userName, profileId, userId } });
    } catch (error) {
      if (error.response) {
        console.error(`Login failed: ${error.response.status} - ${error.response.data}`);
      } else {
        console.error(`Login failed: ${error.message}`);
      }
      throw error;
    }
  }

  logout() {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessTokenExpiresAt");
    localStorage.removeItem("refreshTokenExpiresAt");
    localStorage.removeItem("userName");
    localStorage.removeItem("profileId");
    localStorage.removeItem("userId");
    window.location.href = "/login";
  }

  async changePassword(data) {
    try {
      this.checkTokens();

      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) {
        throw new Error("No access token available. Please log in again.");
      }

      const response = await this.getInstanceWithAccessToken().post('/users/password-change', data, {
        headers: {
          'Content-Type': HEADER_CONTENT_TYPE_JSON_AND_CHARSET_UTF8,
          'Accept': '*/*',
          'Authorization': `Bearer ${accessToken}` // Correctly format the Authorization header
        }
      });

      return response.data;
    } catch (error) {
      console.error('Error:', error, localStorage.getItem("accessToken"));
      throw error;
    }
  }

  async getSurvey(profileId, surveyId) {
    try {
      this.checkTokens();

      const response = await this.getInstanceWithAccessToken().get(`/survey-responses/by-profile-id/${profileId}/for-specified-survey/${surveyId}`);

      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }

  async getAllCategories() {
    try {
      this.checkTokens();
      const response = await this.getInstanceWithAccessToken().get('get-all-categories');
      return response.data;          
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }

  async getCourse(courseId) {
    try {
      this.checkTokens();

      const response = await this.getInstanceWithAccessToken().get(`get-course/${courseId}`);

      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }
  
      
  async getAllCourses(searchQuery = '', queryText = '') {
    try {
      // Ensure tokens are valid before making the request
      await this.checkTokens();

      // console.log(queryText);      
      
      // console.log(searchQuery); 

      // Construct the URL with encoded search query
      
      const searchQueryEncoded = searchQuery.trim().replace(/ /g, '%20').replace(/{/g, '%7B').replace(/}/g, '%7D');
    
      const url = `get-all-courses?${searchQueryEncoded}${searchQueryEncoded ? '&' : ''}${queryText.trim()}`;
      // console.log("Query:  ",searchQuery, " Encoded: ",encodeSpecialChars(searchQuery.trim()) )

      // Log the constructed URL
      console.log('Requesting URL:', url);

      // Make the API request with the proper access token
      const response = await this.getInstanceWithAccessToken().get(url);

      // Return the response data
      return response.data;
    } catch (error) {
      console.error('Error fetching courses:', error);
      throw error;
    }
  }

  async getAllCoursesShort() {
    try {
      this.checkTokens();

      const response = await this.getInstanceWithAccessToken().get(`get-all-courses/short`);

      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }

  async getCoursesMatched(userId, status) {
    try {
      this.checkTokens();

      const response = await this.getInstanceWithAccessToken().get(`courses/matched/${userId}/${status}`);

      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }

  async postCourseOpinion(data) {
    try {
      this.checkTokens();

      const response = await this.getInstanceWithAccessToken().post(
        `course-opinion`,
        data,
        {
          headers: {
            'Content-Type': HEADER_CONTENT_TYPE_JSON_AND_CHARSET_UTF8,
          },
        });

      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }

  async patchRecoverPassword(data) {
    try {
      // this.checkTokens();
      console.log(data);

      const response = await this.getInstanceWithAccessToken().patch(
        `users/password-recover`,
        {
          email: data
        },
        {
          headers: {
            'Content-Type': HEADER_CONTENT_TYPE_JSON_AND_CHARSET_UTF8,
          },
        });

      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }

  async getCoursesMatchedAll(userId) {
    try {
      this.checkTokens();

      const response = await this.getInstanceWithAccessToken().get(`courses/matched/${userId}`);

      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }

  async changeCourseStatus(userId, courseId, newStatus) {
    try {
      this.checkTokens();

      const response = await this.getInstanceWithAccessToken().patch(`courses/matched/set-status/${userId}/${courseId}/${newStatus}`);

      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }
  
  async courseMatchWithStatus(userId, courseId, newStatus) {
    try {
      this.checkTokens();

      const response1 = await this.getInstanceWithAccessToken().patch(`courses/user-match/${userId}/${courseId}`);      
      if (response1) {
         const response2 = await this.getInstanceWithAccessToken().patch(`courses/matched/set-status/${userId}/${courseId}/${newStatus}`);
         return response2.data;
      }      
    } catch (error) {
      console.error('Error:', error);
      throw error;    
    }
  }

  async isCourseMatch(userId, courseId) {
    try {
      this.checkTokens();
      const response1 = await this.getInstanceWithAccessToken().get(`courses/matched/exist/${userId}/${courseId}`);
      return response1.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;    
    }
  }


  async getUserData(userId) {
    try {
      this.checkTokens();

      const response = await this.getInstanceWithAccessToken().get(`users-with-profiles/by-user-id/${userId}`);

      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }

  async changeUserName(profileId, userName) {
    try {
      this.checkTokens();

      const response = await this.getInstanceWithAccessToken().put(
        `profiles/basic/${profileId}`,
        ({
          name: userName,
          surname: userName,
          postalCode: "00-000",
          phoneNumber: "000000000"
        }),
        {
          headers: {
            'Content-Type': HEADER_CONTENT_TYPE_JSON_AND_CHARSET_UTF8,
          },
        });

      return response.data;
    } catch (error) {
      console.error('Error:', {
        name: userName,
        surname: userName,
        postalCode: "00-00",
        phoneNumber: "000000000"
      });
      throw error;
    }
  }

  async sendEmail(data) {
    try {
      this.checkTokens();

      const response = await this.getInstanceWithAccessToken().post(
        `send-message`,
        data,
        {
          headers: {
            'Content-Type': HEADER_CONTENT_TYPE_JSON_AND_CHARSET_UTF8,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }

  async finishCourse(data) {
    try {
      this.checkTokens();

      const response = await this.getInstanceWithAccessToken().post(
        `courses-reasons`,
        data,
        {
          headers: {
            'Content-Type': HEADER_CONTENT_TYPE_JSON_AND_CHARSET_UTF8,
          },
        });

      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }

  async getAttendanceLastWeek(userId) {
    try {
      this.checkTokens();

      const response = await this.getInstanceWithAccessToken().get(`engagement-metrics/last_7_days?userId=${userId}`);

      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }

  async getAttendanceAllData(userId) {
    try {
      this.checkTokens();

      const response = await this.getInstanceWithAccessToken().get(`engagement-metrics?userId=${userId}`);

      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }

  async getReviews(courseId) {
    try {
      this.checkTokens();

      const response = await this.getInstanceWithAccessToken().get(`course-opinion/${courseId}`);

      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }

  async addComment(data) {
    try {
      this.checkTokens();

      const response = await this.getInstanceWithAccessToken().post(
        `course-opinion/comments`,
        data,
      {
          headers: {
            'Content-Type': HEADER_CONTENT_TYPE_JSON_AND_CHARSET_UTF8,
          },
        });

      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }

  async getComment(opinionId) {
    try {
      this.checkTokens();

      const response = await this.getInstanceWithAccessToken().get(`course-opinion/${opinionId}/comments`);

      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }

  async markStudyDay(userId) {
    try {
      this.checkTokens();

      const response = await this.getInstanceWithAccessToken().patch(`engagement-metrics/mark-study-day/${userId}`);

      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }
}

export const backendClient = new BackendClient();

export const fetchBasicSurveyData = async (id) => {
  const encodedCredentials = getEncodedCredentials();

  const response = await fetch(`${API_BASE_URL}/get-survey/${id}`, {
    method: 'GET',
    headers: {
      'accept': '*/*',
      'Authorization': `Basic ${encodedCredentials}`
    }
  });

  if (!response.ok) {
    throw new Error(`Error: ${response.status}`);
  }

  return response.json();
};

export const getBasicSurvey = async () => {
  const encodedCredentials = getEncodedCredentials();

  const response = await fetch(`${API_BASE_URL}/config/get-deployed-basic-survey-id`, {
    method: 'GET',
    headers: {
      'accept': '*/*',
      'Authorization': `Basic ${encodedCredentials}`
    }
  });  

  if (!response.ok) {
    throw new Error(`Error: ${response.status}`);
  }

  return response.json();
};

export const getExtSurvey = async () => {
  const encodedCredentials = getEncodedCredentials();

  const response = await fetch(`${API_BASE_URL}/config/get-deployed-expanding-survey-id`, {
    method: 'GET',
    headers: {
      'accept': '*/*',
      'Authorization': `Basic ${encodedCredentials}`
    }
  });  

  if (!response.ok) {
    throw new Error(`Error: ${response.status}`);
  }

  return response.json();
};

export const fetchExpandedSurveyData = async (id) => {
  const encodedCredentials = getEncodedCredentials();

  const response = await fetch(`${API_BASE_URL}/get-survey/${id}`, {
    method: 'GET',
    headers: {
      'accept': '*/*',
      'Authorization': `Basic ${encodedCredentials}`
    }
  });

  if (!response.ok) {
    throw new Error(`Error: ${response.status}`);
  }

  return response.json();
};

export const createEmptyProfile = async () => {
  const response = await fetch(`${API_BASE_URL}/profiles/empty`, {
    method: 'POST',
    headers: {
      'accept': 'application/json',
      'Content-Type': HEADER_CONTENT_TYPE_JSON_AND_CHARSET_UTF8
    },
    body: JSON.stringify({})
  });

  if (!response.ok) {
    throw new Error(`Error: ${response.status}`);
  }

  return response.json();
};

export const submitSurveyResponses = async (surveyId, profileId, answers) => {
  const encodedCredentials = getEncodedCredentials();

  const response = await fetch(`${API_BASE_URL}/post-survey-responses`, {
    method: 'POST',
    headers: {
      'accept': 'application/json',
      'Content-Type': HEADER_CONTENT_TYPE_JSON_AND_CHARSET_UTF8,
      'Authorization': `Basic ${encodedCredentials}`
    },
    body: JSON.stringify({
      surveyId: surveyId,
      profileId: profileId,
      questionResultRequests: answers
    })
  });

  if (!response.ok) {
    throw new Error(`Error: ${response.status}`);
  }

  return response.json();
};

export const fetchSurveyResults = async (surveyResultId) => {
  const encodedCredentials = getEncodedCredentials();

  const response = await fetch(`${API_BASE_URL}/basic-survey-results/${surveyResultId}`, {
    method: 'GET',
    headers: {
      'accept': '*/*',
      'Authorization': `Basic ${encodedCredentials}`
    }
  });

  if (!response.ok) {
    throw new Error(`Error: ${response.status}`);
  }

  return response.json();
};

export const registerUser = async (data) => {
  const response = await fetch(`${API_BASE_URL}/register`, {
    method: 'POST',
    headers: {
      'accept': 'application/json',
      'Content-Type': HEADER_CONTENT_TYPE_JSON_AND_CHARSET_UTF8
    },
    body: JSON.stringify(data)
  });

  if (!response.ok) {
    throw new Error(`Error: ${response.status}`);
  }

  return response;
};

export const updateDemData = async (data, profileId) => {
  const encodedCredentials = getEncodedCredentials();

  const response = await fetch(`${API_BASE_URL}/profiles/advanced/${profileId}`, {
    method: 'PUT',
    headers: {
      'accept': 'application/json',
      'Content-Type': HEADER_CONTENT_TYPE_JSON_AND_CHARSET_UTF8,
      'Authorization': `Basic ${encodedCredentials}`
    },
    body: JSON.stringify(data)
  });

  if (!response.ok) {
    const errorText = await response.text();
    console.error(`Error: ${response.status} - ${errorText}`);
    console.log(JSON.stringify(data));
    throw new Error(`Error: ${response.status}`);
  }

  return response.json();
};
