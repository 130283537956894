import React, { useMemo, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import TestChoice from '../basicTest/TestChoice';
import dialog from '../../img/dialog.png';
import { Bars, ThreeDots } from 'react-loader-spinner';
import {
  submitSurveyResponses,
  fetchExpandedSurveyData,
  getExtSurvey
} from '../../api/api';
import DemQuestion from './DemQuestion';
import { BackendClient } from '../../api/api';



const ExpandTest = () => {
  const backendClient = useMemo(() => new BackendClient(), []);
  const { profileId, isNewProfile } = useParams();
  const [surveyData, setSurveyData] = useState({
    surveyId: 1,
    surveyType: "EXPANDING_SURVEY",
    surveyName: "Czas dopasować do Ciebie kursy!",
    questions: []
  });
  const [qIndex, setQIndex] = useState(0);
  const [answers, setAnswers] = useState([]);
  const currentProgres = ((qIndex / surveyData.questions.length) * 100);
  const [questionLoading, setQuestionLoading] = useState(true);
  const [demQuestion, setDemQuestion] = useState(false);

  const navToDemQuestions = () => {
    setDemQuestion(!demQuestion);
  }

  // Next test question
  const nextQuestion = () => {
    setQuestionLoading(true);
    setQIndex(qIndex + 1);
    setTimeout(() => {
      setQuestionLoading(false);
    }, 1000);
  }

  // Previous test question
  const prevQuestion = () => {
    if (qIndex > 0) {
      setQuestionLoading(true);
      setQIndex(qIndex - 1);
      setTimeout(() => {
        setQuestionLoading(false);
      }, 1000);
    }
  };

  // Save answer of basic test
  const saveAnswer = (questionId, answer) => {
    setAnswers(prevAnswers => {
      const updatedAnswers = prevAnswers.filter(a => a.questionId !== questionId);
      return [...updatedAnswers, { questionId, answer }];
    });
  };

  // Submit test
  const submitTest = async () => {
    if (answers.length === surveyData.questions.length) {
      try {
        const updatedData = answers.map(item => {
          if (Array.isArray(item.answer)) {
            return {
              ...item,
              answer: item.answer.join(', ')
            };
          }
          return item; 
        });
        await submitSurveyResponses(surveyData.surveyId, profileId, updatedData);
      } catch (err) {
        console.log(err.message);
      }
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const extSurveyId = await getExtSurvey();
        const data = await fetchExpandedSurveyData(extSurveyId);
        setSurveyData(data);
        setQuestionLoading(false);
      } catch (err) {
        console.log(err.message);
      }
    };

    fetchData();
  }, []);
  return (
    <div className='w-full full-height-minus-5rem bg-lightBeige flex flex-col items-center justify-start overflow-hidden'>
      <div className='w-[90vw] lg:w-[1000px]'>

        {/* Header */}
        <h1 className='text-2xl md:text-4xl my-2 md:my-6'>
          Czas dopasować do Ciebie <span className='text-bronze font-semibold'>kursy</span>!
        </h1>

        {/* Progres bar */}
        <div className='w-full h-[15px] md:h-[20px] border-2 border-bronze rounded-xl my-1 md:my-4'>
          <div
            className="rounded-xl bg-maroon h-full transition-all duration-500"
            style={{ width: `${currentProgres}%` }}
          >
          </div>
        </div>


        <div className={`${demQuestion ? 'w-full' : 'w-full lg:w-4/5'} h-[350px]`}>
          {surveyData.questions.length > 0 ? (
            <>
              {questionLoading ? (
                <div className='w-full h-3/5 flex items-center justify-center'>

                  {/* all questions loader */}

                  <ThreeDots
                    visible={true}
                    height="150"
                    width="150"
                    color="#812329"
                    radius="9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </div>
              ) : (

                // Single test block
                <div>
                  {demQuestion ? (
                    <DemQuestion
                      prevQuestion={navToDemQuestions}
                      profileId={profileId}
                      submitTest={submitTest}
                      isNewProfile={isNewProfile}
                    />
                  ) : (
                    <TestChoice
                      qIndex={qIndex}
                      qData={surveyData.questions[qIndex]}
                      nextQuestion={nextQuestion}
                      prevQuestion={prevQuestion}
                      saveAnswer={saveAnswer}
                      submitTest={navToDemQuestions}
                      lastQuestion={qIndex === surveyData.questions.length - 1}
                      currentAnswer={answers.find(a => a.questionId === surveyData.questions[qIndex].questionId)?.answer}
                    />
                  )}
                </div>
              )}
            </>
          ) : (

            // All questions loader
            <div className='w-full h-full flex items-center justify-center'>
              <Bars
                height="250"
                width="250"
                color="#812329"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          )}
        </div>
      </div>


      {/* Gnomek block */}    
      <div className='h-full w-2/5 relative hidden sm:block short:scale-50'>
        <div className='w-[70%]'>
          <img
            src={dialog}
            alt="dialog"
            className='absolute left-[-280px] bottom-[140px] short:left-[-550px] short:bottom-[100px]'
          />
          <p className='absolute left-[-240px] bottom-[220px] text-lg font-medium text-center short:left-[-510px] short:bottom-[180px]'>
            Do dzieła!<br />
            <div className='text-sm flex text-center leading-5 mt-1'>Teraz musisz powiedzieć<br /> mi coś o sobie.</div>
          </p>
        </div>
        <img
          src={`https://images.weserv.nl/?url=${backendClient.getBaseUrl()}/images/50&n=false`}
          alt="gnomek"
          className='absolute w-72 left-[-70px] z-10 bottom-[-75px] short:left-[-350px] short:bottom-[-165px]'
          style={{ transform: 'rotateY(180deg)' }}
        />
      </div>
    </div>
  )
}

export default ExpandTest
