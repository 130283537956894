import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SlArrowDown } from "react-icons/sl";
import { BackendClient, getBasicSurvey, getExtSurvey } from '../../api/api';

const Answers = () => {
  const profileId = localStorage.getItem('profileId');
  const [openBasic, setOpenBasic] = useState([]);
  const [openExt, setOpenExt] = useState([]);
  const [bsSurveys, setBsSurveys] = useState([]); // Zmieniono na bsSurveys
  const [exSurveys, setExSurveys] = useState([]); // Zmieniono na exSurveys
  const backendClient = useMemo(() => new BackendClient(), []);

  const toggleBasic = (index) => {
    setOpenBasic((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const toggleExt = (index) => {
    setOpenExt((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const getBasicSurveyR = useCallback(async (bId, eId) => {
    try {
      const responceB = await backendClient.getSurvey(profileId, bId);
      const responceE = await backendClient.getSurvey(profileId, eId);
      setBsSurveys(responceB); // Zaktualizowano nazwę stanu
      setExSurveys(responceE); // Zaktualizowano nazwę stanu
    } catch (error) {
      console.error(error); // Użyj console.error dla błędów
    }
  }, [backendClient, profileId]);

  const getIds = useCallback(async () => {
    try {
      const basicSurvId = await getBasicSurvey();
      const extSurveyId = await getExtSurvey();
      getBasicSurveyR(basicSurvId, extSurveyId);
    } catch (error) {
      console.error(error); // Użyj console.error dla błędów
    }
  }, [getBasicSurveyR]);

  useEffect(() => {
    getIds();
  }, [getIds]);

  return (
    <div className='font-light mb-16 md:mb-0'>
      <h1 className='font-semibold text-lg'>Odpowiedzi ankiet</h1>
      <h2>W tej zakładce znajdują się odpowiedzi do pytań, które udzieliłeś w ankietach.</h2>

      {/* Basic Survey Section */}
      <div>
        {bsSurveys.map((survey, index) => (
          <div key={index}> 
            <div
              className={`w-full flex items-center animateSl hover:bg-[#C29E7366] p-1 mt-2 ${openBasic[index] ? 'bg-[#C29E7366]' : ''}`}
              onClick={() => toggleBasic(index)}
            >
              <p className='w-[5%]'>{index + 1}</p>
              <p className='w-[20%]'>{survey.date}</p>
              <p className='w-[25%]'>{survey.surveyName}</p>
              <div className='w-1/2 flex justify-end items-center cursor-pointer'>
                <SlArrowDown className={`mr-2 w-4 h-4 transform transition-transform ${openBasic[index] ? 'rotate-180' : ''}`} />
              </div>
            </div>
            {openBasic[index] && ( // Wyświetlamy odpowiedzi tylko dla otwartej ankiety
              <div>
                <div className='w-full flex border-b border-[#ccc] p-1 mt-2'>
                  <p className='w-[20%]'>Nr pytania</p>
                  <p className='w-[35%]'>Treść pytania</p>
                  <p className='w-[42%] pl-[3%]'>Twoja odpowiedź</p>
                </div>
                {survey.questionsResult.map((item, questionIndex) => (
                  <div className='w-full flex border-b border-[#ccc] p-1' key={`basic-${index}-${questionIndex}`}>
                    <p className='w-[20%]'>{questionIndex + 1}</p>
                    <p className='w-[35%]'>{item.question}</p>
                    <p className='w-[42%] pl-[3%]'>{item.answer}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>


      {/* Extension Survey Section */}
      <div>
        {exSurveys.map((survey, index) => (
          <div key={index}>
            <div
              className={`w-full flex items-center animateSl hover:bg-[#C29E7366] p-1 mt-2 ${openExt[index] ? 'bg-[#C29E7366]' : ''}`}
              onClick={() => toggleExt(index)}
            >
              <p className='w-[5%]'>{index + 1}</p>
              <p className='w-[20%]'>{survey.date}</p>
              <p className='w-[25%]'>{survey.surveyName}</p>
              <div className='w-1/2 flex justify-end items-center cursor-pointer'>
                <SlArrowDown className={`mr-2 w-4 h-4 transform transition-transform ${openExt[index] ? 'rotate-180' : ''}`} />
              </div>
            </div>
            {openExt[index] && ( // Wyświetlamy odpowiedzi tylko dla otwartej ankiety
              <div>
                <div className='w-full flex border-b border-[#ccc] p-1 mt-2'>
                  <p className='w-[20%]'>Nr pytania</p>
                  <p className='w-[35%]'>Treść pytania</p>
                  <p className='w-[42%] pl-[3%]'>Twoja odpowiedź</p>
                </div>
                {survey.questionsResult.map((item, questionIndex) => (
                  <div className='w-full flex border-b border-[#ccc] p-1' key={`ext-${index}-${questionIndex}`}>
                    <p className='w-[20%]'>{questionIndex + 1}</p>
                    <p className='w-[35%]'>{item.question}</p>
                    <p className='w-[42%] pl-[3%]'>{item.answer}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Answers;
