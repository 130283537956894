import React, { useMemo } from 'react'
import { FaRegStar } from "react-icons/fa";
import { FaStar } from "react-icons/fa";
import { FaStarHalfStroke } from "react-icons/fa6";
import { TbSquareRoundedChevronRight } from "react-icons/tb";
import { Link } from 'react-router-dom';
import { BackendClient } from '../../api/api';

const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  }
  return text;
};


const renderStars = (rating) => {  
  const totalStars = 5;
  const roundedRating = Math.round(rating * 2) / 2;
  const stars = [];

  for (let i = 0; i < totalStars; i++) {
    if (i < roundedRating) {
      if (i + 0.5 === roundedRating) {
        stars.push(<FaStarHalfStroke key={i} className="w-4 h-4 text-bronze" />);
      } else {
        stars.push(<FaStar key={i} className="w-4 h-4 text-bronze" />);
      }
    } else {
      stars.push(<FaRegStar key={i} className="w-4 h-4 text-bronze" />);
    }
  }

  return stars;
};

const CourseBox = ({ course, isCompact, isSmall = false }) => {
  const backendClient = useMemo(() => new BackendClient());
  return (
    <div key={course.id} className={`mt-4 mx-2 flex ${isCompact ? `h-auto w-[90%] ${isSmall ? 'w-full' : 'md:w-[47%] h-auto'} flex-col` : 'h-[180px] w-full'}`}>
      <img
        src={`https://images.weserv.nl/?url=${backendClient.getBaseUrl()}/images/${course.imageId}`}
        alt={course.name}
        className={`${isCompact ? `w-full ${isSmall ? 'h-[250px]' : 'h-40'}` : 'w-2/5'} rounded object-cover object-center`}
      />
      <div className={`${isCompact ? 'w-full mt-2' : 'ml-2'} text-xs font-light flex flex-col justify-between`}>
        <div>
          <div className='flex justify-between'>
            <div className='flex items-center gap-1'>
              <p className='bg-bronze rounded px-1.5 py-0.5 text-white mr-1'>
                {course.ratingOnGoogle}
              </p>
              {renderStars(course.ratingOnGoogle)}
            </div>
          </div>
          <div className='flex gap-2 text-xs mt-0.5'>
            {course.contentCategory.slice(0, 3).map(cat => (
              <p key={cat.id}>
                {cat.name.toLocaleUpperCase()}
              </p>
            ))}
          </div>
          <div className='flex items-center justify-between'>
            <h3 className='font-medium text-sm mt-1'>{course.name}</h3>
            <p className='text-sm font-medium text-bronze'>{course.minPrice} zł</p>
          </div>
        </div>
        <p className='mt-2'>{truncateText(course.benefitsOfCourse, 100)}</p>
        <Link
          to={`/course/${course.id}`}
          className='flex w-fit items-center gap-2 font-normal rounded text-bronze hover:text-gold transition ease-in-out duration-300 cursor-pointer'>
          <p>Zobacz szczegóły</p>
          <TbSquareRoundedChevronRight className='w-5 h-5' />
        </Link>
      </div>
    </div>
  );
};

export default CourseBox
