import React, { useState } from 'react'
import { FaCheck } from "react-icons/fa";
import { updateDemData } from '../../api/api';
import { useNavigate } from 'react-router-dom';

const initialData = [
  {
    name: 'A. Podaj swoją grupę wiekową',
    options: [
      {name: "poniżej 13", value: "UP_TO_13"},
      {name: "13-18", value: "OVER_13_TO_18_INC"},
      {name: "18-24", value: "OVER_18_TO_24_INC"},
      {name: "24-35", value: "OVER_24_TO_35_INC"},
      {name: "35-45", value: "OVER_35_TO_45_INC"},
      {name: "45-60", value: "OVER_45_TO_60_INC"},
      {name: "powyżej 60", value: "OVER_60"},
    ],
    key: 'ageGroup',
  },
  {
    name: 'B. Płeć',
    options: [
      {name: "kobieta", value: "FEMALE"},
      {name: "mężczyzna", value: "MALE"},
    ],
    key: 'gender',
  },
  {
    name: 'D. Status zawodowy',
    options: [
      {name: "bezrobotny", value: "UNEMPLOYED"},
      {name: "student/uczeń", value: "STUDENT"},
      {name: "pracownik etatowy", value: "FULL_TIME_EMPLOYEE"},
      {name: "samozatrudniony", value: "SELF_EMPLOYED"},
      {name: "przędsiębiorca", value: "ENTREPRENEUR"},
    ],
    key: 'professionalStatus',
  },
  {
    name: 'E. Wykonywany zawód',
    options: [],
    key: 'professional',
  },
];
const DemQuestion = ({prevQuestion, profileId, submitTest, isNewProfile}) => {
  const navigate = useNavigate();
  const [demAnswers, setDemAnswers] = useState({
    ageGroup: "",
    gender: "",
    professionalStatus: "",
    professional: "",
  });

  const saveAnswer = (key, value) => {
    setDemAnswers(prevAnswers => ({
      ...prevAnswers,
      [key]: value
    }));
  };

  const handleTextareaChange = (key, event) => {
    saveAnswer(key, event.target.value);
  };

  const areDemAnswersValid = () => {
    for (let key in demAnswers) {
      if (demAnswers[key] === "") {
        return false;
      }
    }
    return true;
  };


  const submitExpTest = async () => {
    if(areDemAnswersValid()) {
      try {
        submitTest();

        updateDemData(demAnswers, profileId);
        
        navigate(`/expanding-test-result/${profileId}/${isNewProfile}`);
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log('dem questions are not valid');
      console.log(demAnswers);
    }
  }

  return (
    <div>
      <h2 className=' font-light'>
        Pytania demograficzne
      </h2>

      <div className='md:ml-2'>
        {initialData.map((item, ind) => (
          <div className='flex items-start mt-3' key={ind}>
            <p className='font-light text-sm md:text-base mb-2 w-[29%] md:w-1/4 flex'>{item.name}</p>
            {item.options.length > 0 ? (
              <div className='flex flex-wrap gap-4 mt-1 w-[71%] md:w-3/4'>
                {item.options.map((opt, index) => (
                  <div className='flex items-center gap-2' key={opt.value}>
                    <label htmlFor={`radio-${ind}-${index}`} className='md:w-5 h-4 md:h-5 w-4 border-[2px] border-bronze rounded flex items-center justify-center'>
                      <input
                        type="radio"
                        name={`radio-${item.key}`}
                        className='hidden'
                        id={`radio-${ind}-${index}`}
                        checked={demAnswers[item.key] === opt.value}
                        onChange={() => saveAnswer(item.key, opt.value)}
                      />
                      {demAnswers[item.key] === opt.value ? (
                        <FaCheck className='text-bronze md:w-4 h-3 md:h-4 w-3' />
                      ) : (
                        <FaCheck className='text-lightBeige md:w-4 h-3 md:h-4 w-3' />
                      )}
                    </label>
                    <p className='text-xs md:text-sm font-light'>{opt.name}</p>
                  </div>
                ))}
              </div>
            ) : (
              <textarea
                className='w-[71%] md:w-full border-[2px] border-bronze rounded p-2 mt-1 text-sm focus:outline-none focus:border-maroon'
                value={demAnswers[item.key] || ''}
                onChange={(e) => handleTextareaChange(item.key, e)}
                rows={4}
                placeholder='Twoja odpowiedź'
              />
            )}
          </div>
        ))}
      </div>


      <div className='flex gap-6 items-center justify-center mt-6'>
        <div
          className='text-maroon text-sm font-semibold cursor-pointer z-50 py-2'
          onClick={() => prevQuestion()}
        >
          Poprzednie pytania
        </div>
        <div
          className='h-8 text-sm bg-bronze text-white flex items-center px-4 rounded gap-3 cursor-pointer hover:bg-gold transition ease-in-out duration-300 z-50'
          onClick={() => submitExpTest()}
        >
          <p>
            Zakończ ankietę
          </p>
        </div>
      </div>
    </div>
  );
}

export default DemQuestion;