import React, { useState, useEffect } from 'react';
import DropdownUp from '../../img/DropdownUp.png';
import DropdownDown from '../../img/DropdownDown.png';
import { FaCheck } from "react-icons/fa";

const Dropdown = ({ title, options, onChange, checkedItems, setCheckedItems }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleCheckboxChange = (id) => {
    console.log(id);
    setCheckedItems((prevCheckedItems) => {      
      if (prevCheckedItems.includes(id)) {
        return prevCheckedItems.filter((item) => item !== id);
      } else {
        return [...prevCheckedItems, id];
      }
    });
  };

  useEffect(() => {
    if (onChange) {
      onChange(checkedItems); // pass the updated checkedItems array to onChange callback
    }
  }, [checkedItems, onChange]);

  return (
    <div className=''>
      <button
        className="w-full text-xl text-left py-2 rounded flex justify-between"
        onClick={() => setIsOpen(!isOpen)}
      >
        {title}
        {isOpen ? (
          <img src={DropdownUp} alt="DropdownUp" className='w-5 h-5' />
        ) : (
          <img src={DropdownDown} alt="DropdownDown" className='w-5 h-5' />
        )}
      </button>
      {isOpen && (
        <div className="w-full font-light">
          {Object.entries(options).map(([key, value]) => (
            <div key={value} className="flex items-center py-2">
              <div
                className='h-4 w-4 border-2 border-bronze rounded mr-2 flex items-center justify-center cursor-pointer'
                onClick={() => handleCheckboxChange(value)}
              >
                {checkedItems.includes(value) && (
                  <FaCheck className='h-3 w-3 text-bronze' />
                )}
              </div>
              {key}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
