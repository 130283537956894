import React, { useCallback, useEffect, useMemo, useState, Fragment } from 'react';
import Filters from './Filters'
import CourseBox from './CourseBox'
import { BackendClient } from '../../api/api';
import Offer from './Offer';
import { ThreeDots } from 'react-loader-spinner';

import ArrowRightIcon from './../../components/ArrowRightIcon';
import ArrowLeftIcon from './../../components/ArrowLeftIcon';

const CourseList = () => {
  const backendClient = useMemo(() => new BackendClient());
  const [courses, setCourses] = useState([]);
  const [querySearch, setQuerySearch] = useState('');
  const [queryText, setQueryText] = useState('page=0');
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [forceUpdate, setForceUpdate] = useState(0);

  const handleQuery = (str) => {
    if (str && str.trim() !== "") {
      setQueryText('page=0');
      setQuerySearch(str);
    }
  };
  const deleteQuery = () => {
    setQuerySearch('');
  };

  const handleQueryText = (str) => {
    if (str && str.trim() !== "") {
      setQueryText('page=0');
      setQuerySearch(str);
      setTimeout(() => {
        const element = document.getElementById('elementToScroll');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    }
  }

  const isScreenBiggerThan1024px = () => {
    return window.matchMedia('(min-width: 1025px)').matches;
  };

  const getCourses = async () => {
    setIsLoading(true);  
    try {      
      const response = await backendClient.getAllCourses(querySearch.length > 0 ? `search=${querySearch}` : '', queryText);
      setCourses(response.content);
      setTotalPages(response.totalPages)      
      setIsLoading(false);
    } catch (error) {
      console.log('Error fetching courses:', error);
      setCourses([]);
    }
  };

  useEffect(() => {
    getCourses();    
  }, [querySearch, forceUpdate])

  const handleSetPage = (newPage) => {
    if (newPage >= 0 && newPage < totalPages) {
      setCurrentPage(newPage);      
      setQueryText(`page=${newPage}`);
      setForceUpdate(prev => prev + 1);
    }        
  };

  const handleIncreasePage = () => {
    const newPage = currentPage + 1;
    if (currentPage < totalPages) {      
      setCurrentPage(newPage);
      setQueryText(`page=${newPage}`);
      setForceUpdate(prev => prev + 1);
    }    
  };

  const handleDecreasePage = () => {
    const newPage = currentPage - 1;
    if (currentPage >= 1) {
      setCurrentPage(newPage);
      setQueryText(`page=${newPage}`);
      setForceUpdate(prev => prev + 1);
    }
  }

  return (
    <div>
      <Offer handleQuery={handleQueryText} />
      <div className='h-min bg-lightBeige py-8 flex justify-center' id='elementToScroll'>
        <div className='w-full lg:w-[1250px] flex flex-col md:flex-row relative px-4 lg:px-0 relative'>
          <div className='w-full md:w-2/5 lg:w-1/4 h-full md:sticky top-6'>
            <Filters
              handleQuery={handleQuery}
              deleteQuery={deleteQuery}              
              setForceUpdate={setForceUpdate}              
            />
          </div>
          <div className='w-full md:w-3/5 lg:w-[70%] h-min md:ml-10'>
            <div className='w-1/5 border-t-2 border-bronze'></div>
            <h2 className='text-4xl mt-4'>Dostępne <span className='text-bronze'>kursy!</span></h2>
            {/* Paginacja */}
            {(totalPages > 0) ? (<div className="flex justify-end items-center mt-3">
                              
                      <input 
                        type="number" 
                        min="1" 
                        max={totalPages} 
                        value={currentPage + 1} // Assuming currentPage is 0-indexed
                        onChange={(e) => {
                          const newPage = Math.max(0, Math.min(totalPages - 1, parseInt(e.target.value) - 1));
                          handleSetPage(newPage);
                        }}
                        className="w-30 ml-3 border border-[#f2eaea] px-2 py-1 rounded" 
                      />

                        {currentPage >= 1 && (
                          <button
                            onClick={() => {
                              handleDecreasePage();                              
                            }}
                            className="p-1 border border-[#f2eaea] bg-white rounded-bl-md rounded-tl-md"
                          >
                            <ArrowLeftIcon />
                          </button>
                        )}
                        {[...Array(totalPages)].map((_, index) => (
                          <Fragment key={index}>
                            {(index === 0 || index === totalPages - 1 || (index > currentPage - 2 && index < currentPage + 2)) ? (
                              <button
                                key={index}
                                className={`py-1 px-3 bg-white border border-[#f2eaea] ${currentPage === index ? 'font-extrabold text-[#770109] border-[#770109]' : 'text-black border-gray-300'}`}
                                onClick={() => handleSetPage(index)}
                              >
                                {index + 1}
                              </button>
                            ) : (
                              (index === currentPage - 2 || index === currentPage + 2) && <span className="px-3">...</span>
                            )}
                          </Fragment>
                        ))}

                        {(currentPage + 1) < totalPages && (
                          <button
                            onClick={() => {
                              handleIncreasePage();                              
                            }}
                            className="p-1 border border-[#f2eaea] bg-white rounded-br-md rounded-tr-md"
                          >
                            <ArrowRightIcon />
                          </button>
                        )}
                      </div>) : ''}

            {isLoading ? (
              <div className='w-full flex items-center justify-center'>
                <ThreeDots
                  visible={true}
                  height="250"
                  width="250"
                  color="#812329"
                  radius="9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            ) : (
              <>
                {courses.length > 0 && (
                  <div className='w-full flex flex-wrap'>
                    {courses.map(course => (
                      <CourseBox course={course} isCompact={true} isSmall={!isScreenBiggerThan1024px()} />
                    ))}
                    <div>
                      
                    </div>                    
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CourseList
